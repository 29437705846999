<template>
  <div>
    <div class="m--lg">
      <div>Buttons</div>
      <hr />

      <getecma-row class="my--lg">
        <getecma-button>Default</getecma-button>
        <getecma-button theme="primary">Primary</getecma-button>
        <getecma-button theme="success">Success</getecma-button>
        <getecma-button theme="info">Info</getecma-button>
        <getecma-button theme="warning">Warning</getecma-button>
        <getecma-button theme="danger">Danger</getecma-button>
      </getecma-row>

      <getecma-row class="my--lg">
        <getecma-button disabled>Default</getecma-button>
        <getecma-button theme="primary" disabled>Primary</getecma-button>
        <getecma-button theme="success" disabled>Success</getecma-button>
        <getecma-button theme="info" disabled>Info</getecma-button>
        <getecma-button theme="warning" disabled>Warning</getecma-button>
        <getecma-button theme="danger" disabled>Danger</getecma-button>
      </getecma-row>

      <getecma-row class="mb--lg">
        <getecma-button plain>Default</getecma-button>
        <getecma-button theme="primary" plain>Primary</getecma-button>
        <getecma-button theme="success" plain>Success</getecma-button>
        <getecma-button theme="info" plain>Info</getecma-button>
        <getecma-button theme="warning" plain>Warning</getecma-button>
        <getecma-button theme="danger" plain>Danger</getecma-button>
      </getecma-row>

      <getecma-row class="mb--lg">
        <getecma-button plain disabled>Default</getecma-button>
        <getecma-button theme="primary" plain disabled>Primary</getecma-button>
        <getecma-button theme="success" plain disabled>Success</getecma-button>
        <getecma-button theme="info" plain disabled>Info</getecma-button>
        <getecma-button theme="warning" plain disabled>Warning</getecma-button>
        <getecma-button theme="danger" plain disabled>Danger</getecma-button>
      </getecma-row>

      <getecma-row class="mb--lg">
        <getecma-button icon="search" />
        <getecma-button theme="primary" icon="pencil" />
        <getecma-button theme="success" icon="check-lg" />
        <getecma-button theme="info" icon="book" />
        <getecma-button theme="warning" icon="chat" />
        <getecma-button theme="danger" icon="eraser" />
      </getecma-row>
    </div>
    <div class="m--lg">
      <div>Header</div>
      <hr />

      <getecma-row class="my--lg">
        <getecma-header size="sm">Meu título</getecma-header>
        <getecma-header size="md">Meu título</getecma-header>
        <getecma-header size="lg">Meu título</getecma-header>
      </getecma-row>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DashboardPage',
};
</script>
